import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "links", "template", "target", "invoice" ]

  connect() {
    document.addEventListener('keydown', function (event) {
      if (event.key === 'Enter'){
        event.preventDefault();

        console.log("not connecting")
        return false
      }
    });
  }

  add(event) {
    event.preventDefault();
    let randomId = new Date().getTime().toString()
    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, randomId);
    this.targetTarget.insertAdjacentHTML('beforebegin', content);
  }

  remove(event) {
    event.preventDefault();
    let wrapper = event.target.closest('tr');

    if (wrapper.dataset.newRecord === 'true'){
      wrapper.remove();
      this.dispatch('selectChange');
    } else {
      wrapper.style.display = 'none';
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      this.dispatch('selectChange');
    }
    if(this.hasInvoiceTarget){
      this.dispatch('invoiceLineRemoved');
    }
  }
}