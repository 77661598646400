import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["netAmount", "discountPercent", "discount", "taxPercent", "tax", "totalAmount"]

  connect() {
    console.log('Invoice main connected')
    this.updateInvoice();
  }

  updateInvoice(){
    let netPrice = 0
    $(".js-total-price").map((_,el) => el.value).get().forEach((element) => { netPrice += parseFloat(element) });
    this.netAmountTarget.value = netPrice;
    this.totalAmountTarget.value = parseFloat(this.netAmountTarget.value) - parseFloat(this.discountTarget.value || 0) + parseFloat(this.taxTarget.value || 0)
  }

  discountPercentUpdated(){
    if (!isNaN(parseFloat(this.discountPercentTarget.value))){
      this.discountTarget.value = (this.netAmountTarget.value * this.discountPercentTarget.value) / 100
    } else {
      this.discountTarget.value = 0;
    }
    this.updateInvoice();

    console.log(parseFloat(this.discountPercentTarget.value));
  }

  discountUpdated(){
    if (!isNaN(parseFloat(this.discountTarget.value))) {
      this.discountPercentTarget.value = (parseFloat(this.discountTarget.value) / this.netAmountTarget.value) * 100
    } else {
      this.discountPercentTarget.value = 0;
    }
    this.updateInvoice();
  }


  taxPercentUpdated(){
    if (!isNaN(parseFloat(this.taxPercentTarget.value))){
      this.taxTarget.value = (parseFloat(this.netAmountTarget.value) * parseFloat(this.taxPercentTarget.value)) / 100
    } else {
      this.taxTarget.value = 0;
    }
    this.updateInvoice();

  }

  taxUpdated(){
    if (!isNaN(parseFloat(this.taxTarget.value))) {
      this.taxPercentTarget.value = (parseFloat(this.taxTarget.value) / parseFloat(this.netAmountTarget.value)) * 100
    } else {
      this.taxPercentTarget.value = 0;
    }
    this.updateInvoice();
  }

}