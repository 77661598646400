import { Controller } from "stimulus";


export default class extends Controller {

  connect() {
  }

  selectChange(){
    let emptyInputs = $(".autoComplete_wrapper input").filter(function() {
      return $(this).val() === "";
    });
    if (emptyInputs.length > 0){
      return
    }
    this.dispatch('selectChange')
  }
}