import {Controller} from "stimulus";
import autoComplete from "@tarekraafat/autocomplete.js/dist/autoComplete";


export default class extends Controller {
  static targets = ["ctn", "pcs", "quantity"]
  static values = { packing: Number }
  
  connect() {
  }
  
  quantityUpdated(){
    this.quantityTarget.value = (this.filterNumber(this.packingValue) * this.filterNumber(this.ctnTarget.value)) + this.filterNumber(this.pcsTarget.value);
  }
  
  
  filterNumber(num){
    return parseFloat(num) || 0
  }
}