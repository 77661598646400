import { Controller } from "stimulus";
import autoComplete from "@tarekraafat/autocomplete.js/dist/autoComplete";


export default class extends Controller {
  static targets = ["dropdown", "container", "containerLineForeignCurrencyPrice", "containerLineQuantity", "ctn", "pcs", "packing", "quantity"]
  static values = { render: Boolean, url: String,  container_details: Object, dropdown: Boolean, placeholder: String, redirecturl: String, filter: String }

  connect(){
    this.setupSlimSelect(this.dropdownTarget)
    this.setupContainerDetails();
  }

  setupSlimSelect(element) {
    // debugger
    if (this.hasUrlValue && !this.dropdownValue) {

      const autoCompleteJS = new autoComplete({
        selector: `#${this.dropdownTarget.id}`,
        placeHolder: "Search",
        data: {
          src: async (query) => {
            try {

              const loadedList = localStorage.getItem('items')
              if(loadedList == null){
                // Fetch Data from external Source
                const source = await fetch(this.urlValue);
                // Data should be an array of `Objects` or `Strings`
                const data = await source.json();
                localStorage.setItem('items', JSON.stringify(data))
                return data;
              } else {
                return JSON.parse(loadedList)
              }
              // debugger

            } catch (error) {

              return error;
            }
          },
          keys: ["text"],
          cache: true,
        },
        resultsList: {
          element: (list, data) => {
            if (!data.results.length) {
              // Create "No Results" message element
              const message = document.createElement("div");
              // Add class to the created element
              message.setAttribute("class", "no_result");
              // Add message text content
              message.innerHTML = `<span>Found No Results for "${data.query}"</span>`;
              // Append message element to the results list
              list.prepend(message);
            }
          },
          noResults: true,
        },
        resultItem: {
          highlight: true,
        },
      });



      autoCompleteJS.input.addEventListener("selection", function (event) {
        const feedback = event.detail;
        const selection = feedback.selection.value[feedback.selection.key];
        autoCompleteJS.input.value = selection;
        let elementId = $(this).attr('id').replace('temp_item_id','');
        let itemBatchPath = `/items/${feedback.selection.value["id"]}.json`;

        $.ajax({ url: itemBatchPath })
          .done(function(data){
            $(`#${elementId}quantity`).val('');
            $(`#${elementId}item_id`).val(feedback.selection.value["id"]);
            $(`#${elementId}packing`).val(feedback.selection.value["packing"]);

            $(`label[for*='${elementId}quantity']`).html(data.quantity);
          });
        autoCompleteJS.input.focus();

        console.log(feedback.selection.value);
      });

    } else {
      // debugger
      $(this.dropdownTarget).select2({
        width: '100%',
        placeholder: this.hasPlaceholderValue ? this.placeholderValue : 'Select'
      });
      if (this.hasFilterValue) {
        $(this.dropdownTarget).on('select2:select', function(){
          let event = new Event('change') // fire a native event
          this.dispatchEvent(event);
        })
      }

      if (this.redirecturlValue) {
        $(this.dropdownTarget).on('select2:select', function(){
          window.location.href = `${this.dataset.redirecturl}/${$(this).val()}`
        })
      }
    }
  }

  quantityUpdated(){
    debugger
    this.quantityTarget.value = (this.filterNumber(this.packingTarget.value) * this.filterNumber(this.ctnTarget.value)) + this.filterNumber(this.pcsTarget.value);
  }

  filterNumber(num){
    return parseFloat(num) || 0
  }

  setupContainerDetails(){
    $(this.dropdownTarget).on('select2:open', function(){
      $(`[aria-controls='select2-${this.id}-results']`)[0].focus();
    });

    if (!this.hasContainerTarget){
      return
    };

    $(this.dropdownTarget).on('select2:select', function(){
      let elementId = $(this).attr('id').replace('item_id','');
      let itemBatchPath = `/items/${$(this).val()}.json`;
      $.ajax({ url: itemBatchPath })
        .done(function(data){
          $(`#${elementId}quantity`).val(1);
          $(`label[for*='${elementId}quantity']`).html(data.quantity);
        });
    })
  }
}