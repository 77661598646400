import {Controller} from "@hotwired/stimulus";
import toastr from "toastr";

export default class extends Controller {
  connect() {
    let type = this.element.dataset.toastrTypeValue;
    let message = this.element.dataset.toastrMessageValue;

    // Auto remove the flash messages
    setTimeout(() => {
      this.element.remove();
    }, 1000);

    // Create Tostr flash messages
    if (type !== ""){
      toastr[type](message);
    }
  }
}